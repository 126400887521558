<template>
<a-layout-content class="ant-layout-system">
    <a-tabs default-active-key="jobs" >
        <a-tab-pane key="jobs" tab="Basic settings">


            <div class="container-settings">
             
                <a-divider orientation="left">
                    Script Runner 
                </a-divider>
                <p><b>Execuion log window
                        <a-tooltip>
                            <template slot="title">
                                The execution log window position, you can choose up, down, left, right
                            </template>
                            <a-icon type="question-circle" style="margin-left: 3px;margin-right: 5px;" />
                        </a-tooltip>
                    </b>: <a-radio-group default-value="right" button-style="solid" size="small" style="margin-left: 10px;" :value="logDivPlacement" @change="onChangLogDivPlacement">
                        <a-radio-button value="top">
                            up
                        </a-radio-button>
                        <a-radio-button value="bottom">
                            Down
                        </a-radio-button>
                        <a-radio-button value="left">
                            left
                        </a-radio-button>
                        <a-radio-button value="right">
                            right
                        </a-radio-button>
                    </a-radio-group>
                </p>
                <br>
                <a-divider orientation="left">
                    API settings
                </a-divider>

                <a-alert class="alert-settings" message="hint" type="info" show-icon>
                    <span slot="description">API KEY Used for the use of the platform interface, click the button to generate randomly.</span>
                </a-alert>

                <a-row type="flex">
                    <a-col flex="80px">
                        <span class="title">API KEY :</span>
                    </a-col>
                    <a-col flex="auto">
                        <a-tag color="green" style="margin-top:1px;">{{api_key}}</a-tag>
                    </a-col>
                    <a-col flex="120px">
                        <a-button class="btnx" type="primary" icon="key" @click="onUpdateApiKey">generate KEY</a-button>
                    </a-col>
                </a-row>
            </div>
        </a-tab-pane>
        <a-tab-pane key="flsz" tab="Classification">
            <div class="container-settings">
         
                <a-divider orientation="left">
                    Script classification
                </a-divider>
                <span v-for="item in tagData">
                    <a-tag v-if="item.type===1" class="tagx" @click="onChangTag(item.id,item.name,item.type)">
                        {{item.name}}
                    </a-tag>
                </span>

                <a-button size="small" type="primary tagbtn" icon="plus-circle" class="align btn_add" @click="onNewTag(1)"></a-button>

                <br><br>

                <a-divider orientation="left">
                    Variable classification
                </a-divider>
                <span v-for="item in tagData">
                    <a-tag v-if="item.type===2" class="tagx" @click="onChangTag(item.id,item.name,item.type)">
                        {{item.name}}
                    </a-tag>
                </span>
                <a-button size="small" type="primary tagbtn" icon="plus-circle" class="align btn_add" @click="onNewTag(2)"></a-button>
            </div>

            <a-modal v-model="visibleAddTag" :title="tagName">
                <template slot="footer">
                    <a-button type="primary" @click="onAddTag">
                        Add
                    </a-button>
                </template>

                <a-input v-model="tag_name" placeholder="Please enter the classification name" />
            </a-modal>

            <a-modal v-model="visibleUpdateTag" :title="tagName">
                <template slot="footer">
                    <a-button type="primary" @click="onDelTag">
                        delete
                    </a-button>

                    <a-button type="primary" @click="onUpdateTag">
                        renew
                    </a-button>
                </template>

                <a-input v-model="tag_name" placeholder="Please enter the classification name" />
            </a-modal>
        </a-tab-pane>

        <a-tab-pane key="danger" tab="Danger Zone">
            <div class="container-settings">
                <a-alert class="alert-settings" message="Danger Zone" description="These actions are not reversible." type="error" show-icon />
                <a-list item-layout="horizontal">
                    <a-list-item>
                        <a-list-item-meta title="Empty script" description="Clear the script data cannot be restored, the script data is important, please do it carefully"></a-list-item-meta>
                        <a-popconfirm slot="actions" title="Whether to clear the script data?" ok-text="yes" cancel-text="no" @confirm="del(1)">
                                <a-button type="danger"  icon="delete">
                                    Delete
                                </a-button>
                        </a-popconfirm>
                    </a-list-item>
                    <a-list-item>
                        <a-list-item-meta title="Empty log" description="Clear log data cannot be recovered, please operate carefully"></a-list-item-meta>
                        <a-popconfirm slot="actions" title="Whether to clear the log data?" ok-text="yes" cancel-text="no" @confirm="del(2)">
                            <a-button type="danger" icon="delete">Delete</a-button>
                        </a-popconfirm>
                    </a-list-item>
                    <a-list-item>
                        <a-list-item-meta title="Empty report" description="Reporting data is not recovered, please do it carefully"></a-list-item-meta>
                        <a-popconfirm slot="actions" title="Whether to empty the report data?" ok-text="yes" cancel-text="no" @confirm="del(5)">
                            <a-button type="danger" icon="delete">Delete</a-button>
                        </a-popconfirm>
                    </a-list-item>
                    <a-list-item>
                        <a-list-item-meta title="Clear variables" description="This action will clear the variables. Please note that the data will be lost, might cause workflows to fail."></a-list-item-meta>
                        <a-popconfirm slot="actions" title="Do you want to clear the variables?" ok-text="yes" cancel-text="no" @confirm="del(3)">
                            <a-button type="danger" icon="delete">Delete</a-button>
                        </a-popconfirm>
                    </a-list-item>
                    <a-list-item>
                        <a-list-item-meta title="Clear cache" description="This action will clear the cache, you will be prompted to re-login."></a-list-item-meta>
                        <a-popconfirm slot="actions" title="Do you want to clear the cache?" ok-text="yes" cancel-text="no" @confirm="del(4)">
                            <a-button type="danger" icon="delete">Delete</a-button>
                        </a-popconfirm>
                    </a-list-item>
                </a-list>
            </div>
        </a-tab-pane>
        
    </a-tabs>
</a-layout-content>
</template>

<script>

export default {
    name: 'systemHome',
    data() {
        return {
            data: {},
            tagData: {},
            autoit_key: "",
            api_key: "",
            visibleAddTag: false,
            visibleUpdateTag: false,
            tagName: "Script classification",
            tag_name: "",
            curr_type: 1,
            curr_update_id: 0,
            logDivPlacement: "right"
        }
    },
    mounted() {
        this.$store.commit("closeCollapsed");
        this.onLoadTag();
        this.onLoad();
        this.onGetPlacement();
    },
    methods: {
        onLoad() {
            this.$http
                .post("/api/v1/rest/get/system/list")
                .then((res) => {
                    if (res.code == 0) {
                        this.data = res.data;
                        this.autoit_key = this.data.autoit_key;
                        this.api_key = this.data.api_key;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onGetPlacement() {
            this.$http
                .post("/api/v1/rest/get/system/placement")
                .then((res) => {
                    if (res.code == 0) {
                        this.logDivPlacement = res.data.placement;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        del(type) {
            this.$http
                .post("/api/v1/rest/post/system/del", {
                    type: type
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("Successful");
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onUpdateKey() {
            this.$http
                .post("/api/v1/rest/post/system/autoitkey", {
                    autoitkey: this.autoit_key
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("update completed");
                        this.onLoad();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onUpdateApiKey() {
            this.$http
                .post("/api/v1/rest/post/system/apikey")
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("update completed");
                        this.onLoad();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onLoadTag() {
            this.$http
                .post("/api/v1/rest/get/type/list", {
                    type: 0
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.tagData = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onNewTag(type) {
            this.tag_name = "";
            this.curr_type = type;
            if (type === 1) {
                this.tagName = "Script classification"
            } else if (type === 2) {
                this.tagName = "Variable classification"
            }

            this.visibleAddTag = true;
        },
        onChangTag(id, name, type) {
            this.tag_name = name;
            this.curr_type = type;
            this.curr_update_id = id;

            if (type === 1) {
                this.tagName = "Script classification"
            } else if (type === 2) {
                this.tagName = "Variable classification"
            }

            this.visibleUpdateTag = true;
        },
        onDelTag() {
            this.$http
                .post("/api/v1/rest/post/type/del", {
                    id: this.curr_update_id,
                    type: this.curr_type
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("successfully deleted");
                        this.visibleUpdateTag = false;
                        this.onLoadTag();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onAddTag() {
            this.$http
                .post("/api/v1/rest/post/type/add", {
                    type: this.curr_type,
                    name: this.tag_name,
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("Added successfully");
                        this.visibleAddTag = false;
                        this.onLoadTag();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onUpdateTag(e) {
            this.$http
                .post("/api/v1/rest/post/type/update", {
                    id: this.curr_update_id,
                    type: this.curr_type,
                    name: this.tag_name,
                })
                .then((res) => {
                    if (res.code == 0) {
                        this.$message.success("update completed");
                        this.visibleUpdateTag = false;
                        this.onLoadTag();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        onChangLogDivPlacement(e) {
            this.logDivPlacement = e.target.value;

            this.$http
                .post("/api/v1/rest/post/system/placement", {
                    placement: this.logDivPlacement,
                })
                .then((res) => {
                    if (res.code == 0) {} else {
                        this.$message.error(res.msg);
                    }
                });
        },
    }
}
</script>

<style lang="less" scoped>
.bright {
    .container-settings {
        background: #ffffff;
        border-radius: 0px;
        padding: 20px 40px;
        margin-bottom: 20px;

        
        margin: auto;

        .alert-settings {
            margin-bottom: 40px;
        }

        .title {
            font-weight: bold;
            line-height: 27px;
        }

        .text {
            font-weight: bold;
            line-height: 27px;
        }

        .btnx {
            margin-left: 15px;
        }

        .ant-list {
            margin-top: -10px;
        }

        .tagx {
            margin-bottom: 10px;
            cursor: pointer;
        }

        .tagbtn {
            vertical-align: top;
        }
    }
}

.dark {
    .container-settings {
        background: #202020;
        color: #d1d1d1;
        border-radius: 0px;
        padding: 20px 40px;
        margin-bottom: 20px;

        margin: auto;

        .alert-settings {
            margin-bottom: 40px;
        }

        .title {
            font-weight: bold;
            line-height: 27px;
        }

        .text {
            font-weight: bold;
            line-height: 27px;
        }

        .btnx {
            margin-left: 15px;
        }

        .ant-list {
            margin-top: -10px;
        }

        .tagx {
            cursor: pointer;
            margin-bottom: 10px;
        }

        .tagbtn {
            vertical-align: top;
        }
    }

}

.niu {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;


}

.about {

    p {
        text-indent: 20px;
    }
}
</style>
